// extracted by mini-css-extract-plugin
export var container = "achievement-module--container--3TjN3";
export var research = "achievement-module--research--1Isdr";
export var researchTitle = "achievement-module--researchTitle--3ncBg";
export var researchImages = "achievement-module--researchImages--3BTnp";
export var researchImageWrapper = "achievement-module--researchImageWrapper--3gXdl";
export var researchImage = "achievement-module--researchImage--1sjJP";
export var overlay = "achievement-module--overlay--1YDny";
export var contentWrapper = "achievement-module--contentWrapper--WeeaJ";
export var content = "achievement-module--content--1HTmH";
export var close = "achievement-module--close--1QL1d";
export var researchDescription = "achievement-module--researchDescription--15_GW";
export var researchTags = "achievement-module--researchTags--17uHx";
export var researchTag = "achievement-module--researchTag--2wR7U";
export var relativeResearchs = "achievement-module--relativeResearchs--1diJJ";
export var relativeResearchsSubtitle = "achievement-module--relativeResearchsSubtitle--1yIWU";
export var relativeResearchLink = "achievement-module--relativeResearchLink--1ed3Z";
export var relativeResearchDescription = "achievement-module--relativeResearchDescription--4E5x-";
export var relativeResearchImage = "achievement-module--relativeResearchImage--2KZMT";
export var relativeResearchTags = "achievement-module--relativeResearchTags--Hx455";
export var relativeResearchTag = "achievement-module--relativeResearchTag--1dUj9";