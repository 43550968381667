import * as React from 'react'
import { useState } from 'react'
import * as styles from '../components/achievement.module.scss'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Cross from '../images/cross.png'
import GlobalWrapper from '../components/GlobalWrapper'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState()
  const [axisX, setAxisX] = useState()
  const [axisY, setAxisY] = useState()
  const [isModal, setIsModal] = useState(false)
  const settings = {
    arrows: false,
    autoplay: false,
    centerMode: true,
    centerPadding: '0px',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const singleData = data.single.frontmatter
  const listData = data.list

  const handleClickImage = (image, e) => {
    setSelectedImage(image)
    setAxisX(e.clientX)
    setAxisY(e.clientY)
  }

  const handleMouseUpImage = e => {
    const threshold = 150
    if (e.clientX < axisX + threshold && e.clientX > axisX - threshold) {
      if (e.clientY < axisY + threshold && e.clientY > axisY - threshold) {
        setIsModal(true)
        return
      }
    }
    setSelectedImage(null)
    setIsModal(false)
    return
  }

  const handleClickCloseButton = () => {
    setSelectedImage(null)
    setIsModal(false)
  }

  return (
    <>
      <SEO
        description={singleData.description}
        image={singleData.researchImages[0].childImageSharp.gatsbyImageData}
        title={singleData.title}
      />
      <main className={styles.container}>
        {isModal && (
          <div className={styles.overlay}>
            <div className={styles.contentWrapper}>
              <GatsbyImage
                className={styles.content}
                image={selectedImage?.childImageSharp.gatsbyImageData}
                alt=""
              />
              <img
                className={styles.close}
                onClick={handleClickCloseButton}
                src={Cross}
              />
            </div>
          </div>
        )}
        <GlobalWrapper>
          <div className={styles.research}>
            <h1 className={styles.researchTitle}>{singleData.title}</h1>
            <div className={styles.researchImages}>
              <Slider {...settings}>
                {singleData.researchImages.map((image, index) => {
                  return (
                    <div
                      className={styles.researchImageWrapper}
                      key={index}
                      onClick={e => handleClickImage(image, e)}
                      onMouseUp={handleMouseUpImage}
                    >
                      <GatsbyImage
                        className={styles.researchImage}
                        image={getImage(image.childImageSharp.gatsbyImageData)}
                        alt=""
                      />
                    </div>
                  )
                })}
              </Slider>
            </div>
            <p className={styles.researchDescription}>
              {singleData.description}
            </p>
            <div className={styles.researchTags}>
              {singleData.tags.map((tag, index) => {
                return (
                  <p className={styles.researchTag} key={index}>
                    {tag}
                  </p>
                )
              })}
            </div>
          </div>
          <div className={styles.relativeResearchs}>
            <h2 className={styles.relativeResearchsSubtitle}>その他の実績</h2>
            {listData.edges.map(item => (
              <Link
                className={styles.relativeResearchLink}
                to={'/achievements/' + item.node.frontmatter.key}
                key={item.node.frontmatter.key}
              >
                <p className={styles.relativeResearchDescription}>
                  {item.node.frontmatter.title}
                </p>
                <GatsbyImage
                  className={styles.relativeResearchImage}
                  image={getImage(
                    item.node.frontmatter.researchImages[0].childImageSharp
                      .gatsbyImageData
                  )}
                  alt=""
                />
                <div className={styles.relativeResearchTags}>
                  {item.node.frontmatter.tags.map(tag => (
                    <p className={styles.relativeResearchTag} key={tag}>
                      {tag}
                    </p>
                  ))}
                </div>
              </Link>
            ))}
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query ($key: String) {
    single: markdownRemark(
      fileAbsolutePath: { regex: "/achievements/" }
      frontmatter: { key: { eq: $key } }
    ) {
      id
      frontmatter {
        createdYear
        description
        key
        name
        portfolio
        researchImages {
          childImageSharp {
            gatsbyImageData(width: 700)
          }
        }
        title
        tags
      }
    }
    list: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/achievements/" }
        frontmatter: { key: { ne: $key } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            key
            title
            tags
            researchImages {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
